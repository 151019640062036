<template>
  <div class="record-detail">
    <div class="wrap">
      <div class="title">
        <img src="../../public/images/asset/back.png" @click="$router.go(-1)" />
        {{ $t("record.desc15") }}
      </div>
      <div class="list">
        <div class="item">
          <span>{{ $t("record.desc4") }}</span>
          <span>{{ itemObj.type }}</span>
        </div>
        <div class="item">
          <span>{{ $t("record.desc9") }}</span>
          <span>{{ dealAddress(itemObj.address) }}</span>
        </div>
        <div class="item">
          <span>{{ $t("record.desc6") }}</span>
          <span>{{ itemObj.coinName }}</span>
        </div>
        <div class="item">
          <span>{{ $t("record.desc10") }}</span>
          <span>{{ common.cutXiaoNum1(itemObj.amount) }} {{ itemObj.coinName }}</span>
        </div>
        <div class="item">
          <span>{{ $t("record.desc11") }}</span>
          <span>{{ itemObj.dataSn }}</span>
        </div>
        <div class="item">
          <span>{{ $t("record.desc12") }}</span>
          <span>{{ itemObj.date }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      itemObj: {},
    };
  },
  mounted() {
    let obj = localStorage.getItem("detailItem");
    this.itemObj = JSON.parse(obj);
  },
  methods: {
    dealAddress(str) {
      if (str && str.length > 10) {
        return (
          str.substring(0, 6) +
          "...." +
          str.substring(str.length - 4, str.length)
        );
      } else {
        return str;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.record-detail {
  background: #000;
  width: 100%;
  min-height: 100vh;
  padding: 68px 0;
  .wrap {
    margin: 0 auto;
    padding: 0 16px;
    .title {
      height: 71px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      text-transform: capitalize;
      color: #ffe27f;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        width: 17px;
        height: auto;
        cursor: pointer;
      }
    }
    .list {
      padding: 0 16px;
      background: rgba(255, 226, 127, 0.3);
      border-radius: 8px;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        border-bottom: 1px solid #1a1a1a;
        &:last-child {
          border-bottom: none;
        }
        span {
          display: block;
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
          text-transform: capitalize;
          color: #ffffff;
          opacity: 0.6;
          &:last-child {
            font-family: "Bai Jamjuree";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            text-align: right;
            text-transform: capitalize;
            color: #ffffff;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
